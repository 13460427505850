.wrapper {
    display: flex;
    overflow: hidden;
    height: calc(100vh - 90px);
    margin-bottom: 90px;

    @media (max-width: 600px) {
        //mobile
        height: var(--height-mobile-section);
        margin-bottom: var(--heigth-controls-mobile);
    }
}
.sidbar {
    height: calc(100vh - 90px);
    width: 240px;
    @media (max-width: 1200px) {
        //tablet
        width: 70px;
    }
    @media (max-width: 600px) {
        //tablet
        width: 0;
        height: var(--height-mobile-section);
    }
}

.main_page {
    position: relative;
    width: calc(100% - 240px);
    background-color: var(--primary);
    overflow: hidden scroll;
    padding: var(--padding-section);

    @media (max-width: 1200px) {
        //tablet
        width: calc(100% - 70px);
    }
    @media (max-width: 600px) {
        //tablet
        width: 100%;
        padding: 0 10px;
    }
    .box {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 380px;
        background-image: var(--newsonglayout-bg);
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .box-1 {
        z-index: 4;
    }
    .box-2 {
        background-image: linear-gradient(
            180deg,
            hsla(0, 0%, 100%, 0) 37%,
            var(--primary)
        );
        z-index: 3;
    }
    .box-3 {
        opacity: 0.8;
        z-index: 4;
        background-image: linear-gradient(180deg, var(--primary), var(--primary));
    }
}
.header {
    box-shadow: none;
    border: none;
}
.content {
    margin-top: 70px;
    position: relative;
    z-index: 222;
}
