
// code block_songs animation 
.block_container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;   
    height: 18px;
    .block {
        width: 4px;
        background: white;
        margin-left: 1.5px;
        animation: movement 0.8s ease infinite 0s;
    }
    .block:nth-child(1) {
        animation-delay: 0.2s;
        height: 11px;
    }
    .block:nth-child(2) {
        height: 12px;
        animation-delay: 0.3s;
    }
    .block:nth-child(3) {
        height: 10px;
        animation-delay: 0.1s;
    }
    .block:nth-child(4) {
        height: 13px;
        animation-delay: 0.4s;
    }
    @keyframes movement {
        0% {
            height: 8px;
        }
        100% {
            height: 8px;
        }

        50% {
            height: 18px;
        }
    }
}