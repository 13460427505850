.progress_input {
    width: 100%;
    -webkit-appearance: none;
    background-color: grey;
    height: 3px;
    background-image: linear-gradient(var(--purple-primary), var(--purple-primary));
    background-repeat: no-repeat;
        border: none;

    &:hover {
        border-radius: 100px;
        padding: 3px 0; 
        cursor: pointer;
    }

    @media (max-width: 600px) {
        // mobile 
        background-image: linear-gradient(var(--white), var(--white));  
    } 
}

::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 0px;
    width: 0px;
    background-color: var(--purple-primary);
    transition: linear 0.1s; 
    border-radius: 50%;
    @media (max-width: 600px) {
        // mobile 
        background-color: var(--white);  
    } 
}
.progress_input:hover::-webkit-slider-thumb {
    padding: 6px;
    transition: linear 0.1s;
}

// hover vol input
.blur_input {
    padding: 3px 0;
    border-radius: 50px;
}
.blur_input::-webkit-slider-thumb {
    width: 13px;
    height: 13px;
}
