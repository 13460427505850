.wrapper {
    
}
.slider_slick {
}
.content_container {
    margin-top: 20px;
}

// custom scrollbar
::-webkit-scrollbar {
    width: 6px;
    display: block;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset #fff;
    border-radius: 4px;
}
