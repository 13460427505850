// THE LOADING EFFECT
.skeleton {
    background-color: var(--border-player);
    border-radius: 5px;
    height: 10px;
    width: 100%;  
    background-image: linear-gradient(
        90deg,
        transparent,
        hsla(0, 0%, 100%, 0.1),
        transparent
    );
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
}

@keyframes shine {
    to {
        background-position: right -40px top 0;
    }
}
