.wrapper {
    padding-bottom: 30px;
}
.title_header {
    display: flex;
    align-items: center;
    padding: 16px 0;
    margin-bottom: 5px;

    .title_header_section {
        margin-right: 10px;
    }
}
