.wrapper {
    display: flex;
    overflow: hidden;
    height: calc(100vh - 90px);
    margin-bottom: 90px;

    @media (max-width: 600px) {
        //mobile
        height: var(--height-mobile-section);
        margin-bottom: var(--heigth-controls-mobile);
    }
}
.sidbar {
    height: calc(100vh - 90px);
    width: 240px;
    background-color: var(--sidebar-bg);
    @media (max-width: 1200px) {
        //tablet
        width: 70px;
    }
    @media (max-width: 600px) {
        //mobile
        width: 0;
        height: var(--height-mobile-section);
    }
}
.main_page {
    flex: 1;
    width: calc(100% - 240px);
    background-color: var(--primary);
    padding: var(--padding-section);

    @media (max-width: 1200px) {
        //tablet
        width: calc(100% - 70px);
    }
    @media (max-width: 600px) {
        //tablet
        width: 100%;
        padding: 0 10px;
    }
}
.header_main_page {
    background-color: transparent;
    box-shadow: none;
    border: none;
}
//style config
