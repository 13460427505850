:root {
    //color
    --primary-blur: #170f2387;
    --primary: #170f23;
    --primary-bg: #34224f;
    --purple-primary: #9b4de0;
    --primary-blur-bg: rgba(0, 0, 0, 0.05);
    --sidebar-bg: #231b2e;
    --sidebar-popup-bg: #2a213a;
    --layout-header-bg: rgba(23, 15, 35, 0.666);
    --modal-layout-bg: rgba(0, 0, 0, 0.5);
    --player-bg: #130c1c;
    --background-section: '';
    --background-section-size: 1920px auto;
    --newsonglayout-bg: url('https://zjs.zmdcdn.me/zmp3-desktop/releases/v1.9.9/static/media/new-release-bg.73d8f976.jpg');

    --text-primary: #fff;
    --text-blur: #756a87;
    --text-secondary: hsla(0, 0%, 100%, 0.5);
    --link-text-hover: #c273ed;
    --white: #fff;
    --black: #000;
    --button-hide: #595362;
    --search-text: #dadada;

    --hover-blur-bg: rgba(0, 0, 0, 0.05);
    --hover-blur-bg-button: rgba(0, 0, 0, 0.2);

    --border-player: hsla(0, 0%, 100%, 0.1);
    --border-secondary: hsla(0, 0%, 100%, 0.5);

    --padding-section: 0 60px;
    --height-mobile-section: calc(100vh - 70px);
    --heigth-controls-mobile: 70px;
    @media (max-width: 1016px) {
        //tablet
        --padding-section: 0 29px;
    }
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

//font family
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800');
html {
    font-size: 14px;
}
body {
    font-family: 'Inter', sans-serif;
    color: var(--text-primary);
    overflow: overlay; 
}
// controls_container
.control_music {
    border-top: 1px solid var(--border-player);
    position: fixed;
    bottom: 0;
    background-color: var(--primary);
    width: 100%;
    height: 90px;
    z-index: 9999;

    @media (max-width: 600px) {
        //mobile
        height: 70px;
    }
}

button,
input,
textarea {
    outline: none;
    text-decoration: none;
    border: 1px solid transparent;
    color: var(--text-primary);
}
input::placeholder {
    color: var(--text-primary) !important ;
}
a[href] {
    text-decoration: none;
    color: var(--text-primary);
}
li {
    list-style-type: none;
}

// tippy

.tippy-content {
    background-color: #333;
    padding: 3px 5px 5px 5px;
    border-radius: 4px;
    font-size: 12px;
}
.tippy-box[data-pl4cem5nt^='bottom'] > .tippy-arrow:before {
    color: #333;
    top: -8px;
}

.is-collap {
    background-color: var(--primary-bg);
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
    :focus-within:has(input:focus) {
        background-color: var(--primary-bg);
    }
}
.arrow {
    width: 60px;
    padding: 15px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    position: absolute;
    font-size: 25px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    fill: #fff;
    color: var(--white);
    background-color: hsla(0deg, 0%, 100%, 0.15);
    cursor: pointer;
    z-index: 9999;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: hsla(0, 0%, 100%, 0.11);
    }
}

.arrow--left {
    left: 5px;
}

.arrow--right {
    left: auto;
    right: 5px;
}

.arrow--disabled {
    fill: rgba(255, 255, 255, 0.5);
}
//custom button volume same
svg.svg-inline--fa.fa-volume {
    width: 23.5px;
    height: 20px;
}
svg.svg-inline--fa.fa-volume-xmark {
    margin-left: 2px;
    width: 23px;
    height: 20px;
}

// fix icons local
svg.svg-inline--fa.fa-play {
    padding-left: 4px;
}
.container {
    background-attachment: fixed;
}

.swiper-wrapper {
    width: 100vw;
}

// toast msg
.Toastify__toast-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.Toastify__toast {
    border-radius: 4px;
}

//hide horizonScroll
::-webkit-scrollbar-thumb:horizontal {
    display: none;
  }

  //mượn tạm

//   .game {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     margin-top: 20px;
//     position: relative;
//   }
  
//   .game-board {
//     display: flex;
//     flex-direction: column;
//   }
  
//   .board-row {
//     display: flex;
//   }
  
//   .square {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 40px;
//     height: 40px;
//     background-color: #fff;
//     border: 1px solid #999;
//     font-size: 24px;
//     font-weight: bold;
//     // color: black;
//     cursor: pointer;
//   }

//   .square.X {
//     color: black; /* X màu đen */
//   }
  
//   .square.O {
//     color: red; /* O màu đỏ */
//   }
  
//   .game-info {
//     margin-top: 20px;
//     position: absolute;
//     right: calc(100% - 45px);
//     top: 22px;
//     transform: rotate(90deg);
//     width: 100px;
//   }

.board-row {
    display: flex;
  }
  
  .square {
    width: 30px;
    height: 30px;
    background-color: white;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }