 
.card_radio {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    flex-shrink: 0;
    position: relative;
    padding: 10px;
    margin-top: 20px;
    @media (max-width: 1024px) {
        width: 20%;
        padding: 0 12px;
    }
    @media (max-width: 800px) {
        width: 25%;
        padding: 0 12px;
    } 
    @media (max-width: 600px) {
        width: 33.333%;
        padding: 0 12px;
    } 
    @media (max-width: 480px) {
        //mobile
        width: 50%;
        padding: 0 10px;
    }
}

.card_item {
    position: relative;
}
.card_image {
    overflow: hidden;
    border-radius: 999px;
    height: 0;
    padding-bottom: 100%;
    box-shadow: 0 1.22656px 4.90625px rgba(0, 0, 0, 0.16);
    position: relative;

    &.card_image img {
        width: 100%;
        min-height: 130px;
        height: auto;
        transform: scale(1.1);
        object-fit: cover;
    }
}
.card_extra_image {
    position: absolute;
    overflow: hidden;
    border-radius: 999px;
    height: 0;
    z-index: 1111;
    padding-bottom: 25%;
    box-shadow: 0 1.22656px 4.90625px rgba(0, 0, 0, 0.16);
    width: 25%;
    top: 75%;
    right: 0;
    &.card_extra_image img {
        height: auto;
        transform: scale(1);
        border-radius: 999px;
        border: solid 3px var(--white);
        object-fit: cover;
    }
}
.name_artist {
    position: relative;
    text-align: center;
    padding-top: 10px;
    color: var(--text-primary);
    font-weight: 500;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    &:hover {
        color: var(--purple-primary);
        text-decoration: underline;
    }

    @media (max-width: 600px) {
        font-size: 12px;
    }
}
.user_listening {
    color: var(--text-secondary);
    font-size: 12px;
    text-align: center;
}
.card_circle_live {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.unlive img {
    filter: grayscale(1);
}
.btn_action {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border: 1px solid var(--white);
    color: var(--white);

    @media (max-width: 600px) { 
        width: 35px;    
        height: 35px;
    }
}

.radio_playing {
    filter: brightness(0.7);
}
.card_img_btn_live {
    position: absolute !important;
    left: 50%;
    transform: translate(-50%, 50%);
    bottom: 0;
    z-index: 3;
    line-height: 0;
    width: 25%;
}
.isHover img {
    filter: brightness(0.7);
    transform: scale(1.2) !important  ;
    transition: all linear 0.3s;
}
