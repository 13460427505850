.wrapper {
}

.title_section {
    margin: 20px 0;
}
.container_singer_popular {
    display: flex;
    margin-top: 20px;
    width: 100%;
    overflow: hidden;
    @media (max-width: 1130px) {
        //tablet 
    }
    @media (max-width: 600px) {
        //tablet 
        flex-wrap: wrap; 
    }
    .item {
        padding: 0 12px;
        width: 20%;
        flex-shrink: 0;
        @media (max-width: 1200px) {
            //tablet
            width: 25%;
        }
        @media (max-width: 600px) {
            //tablet
            width: 50%;
            padding: 10px;  
        }
        .item_title {
            font-size: 14px;
            padding: 10px 0;
            border-radius: 4px;

            &:hover {
                color: var(--purple-primary);
            }
        }
        .item_extra_title {
            font-size: 14px;
            color: var(--text-secondary);

            &:hover {
                color: var(--purple-primary);
                text-decoration: underline;
            }
        }
    }
}
