//layout bg
.wrapper {
    background-image: var(--background-section);
    background-size: var(--background-section-size);
    background-attachment: fixed;
    position: relative; 
}

// toast 

.toast_msg {
    z-index: 99999;
    
    @media (max-width: 480px) {
        max-width: 70vw;  
        margin: 10px 0;
    }
}