.wrapper {
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 25px;
    width: 100%;
    white-space: nowrap;
    @media (max-width: 1200px) {
        padding: 10px;
    }
}

//Type button
.modalControls {
    color: var(--white);
}
 
.primary {
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--border-player);
    padding: 10px 24px;
    margin-right: 12px;
    color: var(--purple-primary);
    padding: 12px 20px 12px 17px;
    &:hover {
        backdrop-filter: blur(50px);
    }
}
.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 50%;
    margin: 0 4px;
    font-size: 18px;
    width: 40px;
    height: 40px;
    background-color: var(--border-player);
    color: var(--text-primary);
    &:hover {
        background-color: var(--hover-blur-bg-button);
    }
    @media (max-width: 600px) {
        //mobile
        margin: 0;
        width: 35px;
        height: 35px;
    }
}
.circle_hide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    font-size: 20px;
    padding: 15px;
    margin: 0 5px;
    &:hover {
        background-color: var(--border-player);
    }
    @media (max-width: 600px) {
        //mobile
        padding: 0px;
        margin: 0;
        width: 30px;
        height: 30px;
        font-size: 22px;
    }
}
.active {
    color: var(--purple-primary);
}
.text {
    padding: 5px 24px;
    font-size: 12px;
    margin: 0;
    margin-right: 15px;
    background-color: transparent;
    font-weight: 400;
    color: var(--text-primary);
}
.text_border {
    padding: 5px 24px;
    font-size: 12px;
    margin: 0;
    max-width: 120px;
    background-color: var(--primary-blur-bg);
    border-radius: 100px;
    border: 1px solid var(--border-player);
    text-transform: uppercase;
    font-weight: 400;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-primary);

    @media (max-width: 600px) {
        //custom btn
        //mobile
        width: 20%;
        font-size: 10px;
    }
}
.border {
    font-size: 18px;
    border: 2px solid var(--text-primary);
    border-radius: 50%;
    width: 42px;
    height: 42px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    &:hover {
        color: var(--purple-primary);
        border-color: var(--purple-primary);
    }
    @media (max-width: 600px) {
        //tablet
        width: 35px;
        height: 35px;
        border-color: var(--white);
    }
}
.typeSideBar {
    height: 48px; 
    width: 100%;
    @media (max-width:600px) {
        height: 36px;
    }
}
.border_nothover {
    // boder banner playlist song
    font-size: 18px;
    border: 2px solid var(--white);
    border-radius: 50%;
    width: 45px;
    height: 45px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
}
.borderFixPlay {
    padding-left: 3px;
}
.disable { 
    opacity: 0.3;
    cursor: no-drop;
    padding: 4px;
}
.isLoading {
    animation: spin 0.8s linear infinite;
}
.effectHover {
    font-size: 23px;
    margin-left: 10px;
    border: none;
    outline: none;
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    color: var(--purple-primary);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    z-index: 0;
    &:hover {
        color: var(--white);
    }
    &:before {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        transform: translate(-50%, -50%);
        background-color: transparent;
        z-index: -2;
        overflow: hidden;
        transition: all linear 0.2s;
    }

    &:hover::before {
        background-color: var(--purple-primary);
        width: 100%;
        height: 100%;
        border: 1px solid var(--purple-primary);
        border-radius: 100px;
    }
}

.effectHoverReverse {
    font-size: 23px;
    border: none;
    outline: none;
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    color: var(--white);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--purple-primary);
    z-index: 0;
    &:hover {
        color: var(--purple-primary);
    }
    &:before {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        transform: translate(-50%, -50%);
        background-color: transparent;
        z-index: -2;
        overflow: hidden;
        transition: all linear 0.2s;
    }

    &:hover::before {
        background-color: var(--white);
        width: 100%;
        height: 100%;
        border: 1px solid var(--white);
        border-radius: 100px;
    }
}
.isListQueue {
    color: var(--white);
}
.purplePrimary {
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--purple-primary);
    padding: 10px 24px;
    margin-right: 12px;
    color: var(--white);
    font-size: 14px;
    font-weight: 600;
    padding: 8px 20px;
    &:hover {
        backdrop-filter: blur(50px);
    }
}
// Sizes
.min {
    padding: 0;
    padding: 5px 0;
    font-size: 9px;
    color: var(--white);
    border-radius: 10px;
    font-weight: 500;
    width: 100px;
    display: flex;
    justify-content: center;

    @media (max-width: 480px) {
        border-radius: 6px;
    }
}
.small {
    padding: 0;
    width: 34px;
    height: 34px;
    font-size: 16px;
    margin: 0 5px;
}
.normal {
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    padding: 20px;
    color: var(--purple-primary);
}
.medium {
    padding: 16px !important;
    width: 38px;
    height: 38px;
    font-size: 18px;
}
.large {
    width: 52px;
    height: 52px;
    font-size: 25px;

    @media (max-width: 600px) {
        //mobile
        width: 40px;
        height: 40px;
        font-size: 18px;
    }
}
.border_big {
    width: 45px;
    height: 45px;
}
.big {
    font-size: 25px;
    font-weight: 700;
    padding: 18px;
    color: var(--white);
    border-radius: 20px;
    margin: 0 5px;
}
.wider {
    width: 100%;
    padding: 9px 24px;
    border-radius: 20px;
    font-size: 14px;
}
// Custom Icons

.left_icon,
.main_icon,
.right_icon {
    display: flex;
    align-items: center;
    justify-content: center;
}
.main_icon {
    margin: 0 10px;
}
.left_icon {
    margin-right: 10px;
    font-size: 18px;
}
.right_icon {
    margin-left: 10px;
    font-size: 18px;
}
//custom border on button menu
.spederate {
    border-top: 1px solid #493961;  
    width: 100%;
    padding-top: 12px;
    margin: 10px 0; 
}

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

// add isActive button component
.isActive {
    background-color: var(--purple-primary);
    color: var(--white);
}
