.wrapper {
    display: block;
    width: 100%;
    height: 100%; 
    transition: all linear 0.4s;
}

.Icon {
    width: 20px;
    height: 20px;
}
.border {
    border-radius: 100px;
    width: 40px !important;
    height: 40px !important;
}
.btn_arrow_right {
    height: auto;
    border: 1px solid var(--purple-primary);
    border-radius: 999px;
    position: relative;
    padding-top: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
