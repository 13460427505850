.song_item_container {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid var(--border-player);
    border-radius: 5px;
    min-width: 260px;
    &:hover {
        background-color: var(--border-player);
    }
    &:hover .image_song_item img {
        filter: brightness(50%);
    }
    &.isActive {
        background-color: var(--border-player);
        position: relative;
        transition: all ease 0.5s;
    }
    &.isActive .icon_inner_avatar {
        opacity: 1;
    }
    &.isActive .image_song_item img {
        filter: brightness(50%);
    }

    &:hover .icon_inner_avatar {
        opacity: 1;
    }

    // custom item playlistsong when in listqueue
    &.isActiveListQueue {
        background-color: var(--purple-primary);
        transition: all ease 0.5s;

        .title_song_item {
            .name_song_item {
                color: var(--white);
            }
            .name_singer_item {
                color: hsla(0, 0%, 100%, 0.6);
                &:hover {
                    color: hsla(0, 0%, 100%, 0.6);
                }
            }
        }
        .item_format_time {
            color: hsla(0, 0%, 100%, 0.6);
        }
    }

    &.isActiveListQueue .icon_inner_avatar {
        opacity: 1;
    }
    &.isActiveListQueue .image_song_item img {
        filter: brightness(50%);
    }
}

.container_song_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.songs_item_left {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    width: 70%;
    .image_song_item {
        width: 40px;
        height: 40px;
        position: relative;
        cursor: pointer;

        & img {
            border-radius: 4px;
        }
    }
    .title_song_item {
        display: flex;
        flex-direction: column;
        line-height: 1.6;
        margin-left: 10px;
        width: 40%;
        @media (max-width: 600px) {
            width: 40vw;
        }
        .name_song_item {
            font-size: 14px;
            color: var(--text-primary);
            font-weight: 500;
            padding: 0 4px;
            text-transform: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .name_singer_item {
            font-size: 12px;
            padding: 0 4px;
            font-weight: 500;
            text-transform: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            display: inline-block;
            &:hover {
                text-decoration: underline;
                color: var(--link-text-hover);
            }
            a {
                color: var(--text-secondary);
            }
        }
    }
    .icon_inner_avatar {
        position: absolute;
        font-size: 18px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        color: var(--white);
    }
}

.song_item_right {
    padding-right: 5px;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .items_hover {
        display: flex;
        align-items: center;
        justify-content: center; 

        @media (max-width: 600px) {
            gap: 5px;
        }
    }
    .item_format_time {
        margin-right: 10px;
        font-size: 12px;
        font-weight: 500;
        color: var(--text-secondary);
    }
}

.song_prefix {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.number_title {
    -webkit-text-stroke: 1.5px var(--text-primary);
    opacity: 0.7;
    font-weight: 600;
    color: rgba(74, 144, 226, 0);
    font-size: 32px;
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    min-width: 38px;

    .is-top-rank-1,
    .is-top-rank-2,
    .is-top-rank-3 {
        color: rgba(74, 144, 226, 0);
    }

    &.is-top-rank-1 {
        -webkit-text-stroke-color: #4a90e2;
    }
    &.is-top-rank-2 {
        -webkit-text-stroke-color: #50e3c2;
    }
    &.is-top-rank-3 {
        -webkit-text-stroke-color: #e35050;
    }
}
.song_title_rank {
    color: var(--text-secondary);
    font-size: 15px;
}

// styles trending
.HomePageTrending {
    max-height: 80px;

    .image_song_item {
        width: 60px;
        height: 60px;
        cursor: pointer;

        & img {
            border-radius: 4px;
        }
    }
    .song_trending_favorite {
        font-size: 12px;
        color: var(--text-secondary);
        padding: 0 5px;
    }
}
.btn_action {
    color: var(--white);
}
