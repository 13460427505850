.wrapper {
    padding-top: 20px;
    height: 100vh;
}
.container_playlist_detail {
    width: 100%;
    display: flex;

    @media (max-width: 1200px) {
        //tablet
        flex-direction: column;
    }
    .title_section {
        width: 300px;
        height: 600px;
        padding-top: 30px;

        @media (max-width: 1200px) {
            //tablet
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            flex-direction: row;
            padding-bottom: 20px;
            .img_banner {
                width: 200px;
                height: 200px;
            }
        }

        @media (max-width: 600px) {
            //mobile
            display: flex;
            flex-direction: column;
            align-items: center;
            background-image: linear-gradient(
                135deg,
                rgba(255, 255, 255, 0.1),
                rgba(255, 255, 255, 0.1)
            );
            border-radius: 4px;
            backdrop-filter: blur(20px);
            box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
            margin-bottom: 20px;
        }
        .title_header {
            display: flex;
            justify-content: center;
            padding: 16px 0;
            flex-wrap: wrap;
            text-align: center;
            flex-direction: column;
            align-items: center;
            @media (max-width: 1200px) {
                //tablet
                flex-direction: column;
                align-items: flex-start;
                text-align: start;
                margin-left: 20px;
                .btn_effect {
                    padding: 20px 0;
                }
            }
            @media (max-width: 600px) {
                //mobile
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .title_header_section {
                margin-right: 10px;

                @media (max-width: 600px) {
                    //mobile
                    font-size: 18px;
                }
            }
            .title_extra {
                font-size: 12px;
                color: var(--text-secondary);
                font-weight: 500;
                padding: 10px 0;
                display: flex;
                flex-direction: column;
                gap: 10px;
                .singer_outainding {
                    &:hover {
                        color: var(--purple-primary);
                    }
                }
            }
        }
        .banner_section {
            &.banner_section img {
                width: 300px;
                height: 300px;
                border-radius: 10px;
            }
        }
    }
    .container_listsong_full {
        flex: 2;
        padding-left: 20px;
        @media (max-width: 1200px) {
            padding: 0;
        }
        .title_songs_list {
            height: 46px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: var(--text-secondary);
            font-size: 14px;
            font-weight: 500;
            padding: 0 20px;
            border-bottom: 1px solid var(--border-playerdary);
        }
        .list_song {
            height: calc(100vh - 250px);
            overflow-y: scroll;

            @media (max-width: 1200px) {
                //tablet
                height: auto;
                overflow-y: unset;
            }
        }
    }
}
.title_partic_artist {
    font-size: 20px;
}
.partic_artists {
    // artist list
    display: flex;
    padding: 20px 0 30px 0;
    width: 100%;

    overflow: hidden;
    @media (max-width: 600px) {
        //mobile
        overflow-x: scroll;
    }
}
// custom scrollbar
.list_song::-webkit-scrollbar {
    width: 6px;
    display: none;
}
.list_song::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
}
.list_song::-webkit-scrollbar-track {
    -webkit-box-shadow: inset #fff;
    border-radius: 4px;
}
.partic_artists::-webkit-scrollbar-thumb:horizontal {
    display: none;
}
