.wrapper { 
    width: 100%;
    height: 68px;   
    &:hover {
        background-color: var(--border-player);
        border-radius: 4px;

    }
}
.icon_dot {
    font-size: 4px;
    padding: 0 4px;
}
.follower {
    font-size: 12px;
}
// Account
.user_account {
    display: flex; 
    padding: 8px 10px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: var(--border-player);
        border-radius: 4px;

    }
}
.avatar {
    width: 52px;
    height: 52px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;  
}
.user_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.name_singer {
    font-size: 14px;
    font-weight: 500;
}
.sub_title {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: var(--text-secondary);
    display: inline-block;
}
 