.wrapper {
    padding-bottom: 10px;
    .container_card_artist {
        margin: 10px 0 30px 0;
        display: flex;
        overflow: hidden;

        @media (max-width: 600px) {
           
            overflow-x: scroll;
        }
    }
}
.title_songs_list {
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 500;
    padding: 0 20px;
    border-bottom: 1px solid var(--border-playerdary);
}



.container_card_artist::-webkit-scrollbar-thumb:horizontal {
    display: none;
} 