.wrapper {
    background-color: var(--primary-bg);
    border-radius: 8px;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
    width: 240px;
    overflow: overlay;
    z-index: 101;
    position: absolute;
    right: 0;

    .title_song {
        display: flex;
        padding: 10px;
        border-bottom: 1px solid var(--text-primary);
    }
    .img_song {
        height: 40px;
        width: 40px;

        img {
            border-radius: 4px;
        }
    }
    .info_song {
        margin-left: 5px;
        display: flex; 
        justify-content: center;
        flex-direction: column;
    }
    .name_song {
        color: var(--text-primary);
        font-size: 14px;
    }
    .title_extra {
        display: flex;
        gap: 5px;
        .icon_heart,
        .icon_view {
            font-size: 12px;
            color: var(--text-secondary);
        }
    }
}
.menu_items {
    padding: 10px 8px;
}
.items {
    height: 44px;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    &:hover {
        background-color: var(--hover-blur-bg-button);
        border-radius: 4px;
    }
}
.icon {
    color: var(--text-primary);
}
.item_title {
    font-size: 14px;
    color: var(--text-primary);
    font-weight: 500;
}

.textblur {
    .icon {
        color: var(--text-blur);
    }
    .item_title {
        font-size: 14px;
        font-weight: 400;
        color: var(--text-blur);
    }
    &:hover span {
        color: var(--text-primary);
        font-weight: 400;
    }
}
