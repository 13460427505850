.wrapper {
    /*   background-color: rgb(0, 0, 0, 0.05); */

    @media (max-width: 600px) {
        height: var(--height-mobile-section);
        overflow-y: scroll;
    }
}
// Header Account Page
.header_account_page {
    margin-top: 100px;

    @media (max-width: 600px) {
        //mobile
        height: auto;
        margin-bottom: 30px;
    }
    .header_box {
        padding: var(--padding-section);
        display: flex;
        align-items: center;
    }
    .box_singer {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media (max-width: 600px) {
            //mobile
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
        }
    }
    .image_singer {
        width: 140px;
        height: 140px;
        border-radius: 100px;
        margin-right: 40px;

        @media (max-width: 600px) {
            //mobile
            width: 80px;
            height: 80px;
            margin-right: 20px;
        }
    }
    .singer_info {
        display: flex;
        flex-direction: column;
        @media (max-width: 600px) {
            //mobile
            justify-content: center;
            align-items: center;
        }
    }
    .singer_name {
        font-size: 28px;
        font-weight: 600;
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .extra_title {
        font-size: 14px;
        margin-top: 20px;
        font-weight: 500;
        @media (max-width: 600px) {
            //mobile
            margin-top: 0;
        }
    }
}

//content Account Page
.content_account_page {
    background-color: var(--primary);

    .container_songs_popular {
        width: 100%;
        background-color: var(--primary);
        height: auto;
    }
    .title_section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 25px 10px;
        .title_main {
            font-size: 20px;
            font-weight: 600;
        }
        .list_songs_section {
            font-size: 13px;
            font-weight: 400;
            color: var(--text-secondary);

            & span {
                color: var(--text-secondary);
                margin-right: 5px;
            }
        }
    }

    .list_songs {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 28px;

        @media (max-width: 600px) {
            //mobile
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
