.header_title {
    display: flex;
    align-items: center;
    font-size: 35px;
    padding: 20px ; 
    @media (max-width: 600px) {
        //mobile
       padding: 10px;
       font-size: 20px;
    }
    .title_section {
        margin-right: 10px;
        white-space: nowrap;
        @media (max-width: 800px) {
            white-space: pre-wrap;   
            text-align: center;
        }
    }
}
